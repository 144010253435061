// Entry point for the build script in your package.json
//import "@hotwired/turbo-rails"
//import "./controllers"

import "./components/filter";
import autoComplete from "@tarekraafat/autocomplete.js";

document.addEventListener("DOMContentLoaded", () => {
  document.body.classList.remove("preload");

  if ($('select[name="order[stockist_id]"]')) {
    const initial_stock_telephone = $('select[name="order[stockist_id]"]').val();
    const initial_show_stock_telephone = document.querySelector(`.stockist-telephone-${initial_stock_telephone}`);
    if (initial_show_stock_telephone) {
      initial_show_stock_telephone.classList.remove("hide");
    }

    $('select[name="order[stockist_id]"]').on("change", (e) => {
      const all_stockist_telephones = document.querySelectorAll(".stockist-telephone");
      const show_stock_telephone = document.querySelector(`.stockist-telephone-${e.target.value}`);

      all_stockist_telephones.forEach((item) => item.classList.add("hide"));
      if (show_stock_telephone) {
        show_stock_telephone.classList.remove("hide");
      }
    });
  }

  document.querySelector(".nav-toggle").addEventListener("click", (e) => {
    e.currentTarget.classList.toggle("is-active");
    document.querySelector("nav.navigation").classList.toggle("open");
  });

  if (document.getElementById("stock_items_search")) {
    const config = {
      selector: "#stock_items_search",
      placeHolder: "Search for stock items...",
      data: {
        src: async () => {
          try {
            // Fetch Data from external Source
            const source = await fetch(`/admin/stock_items/autocomplete`);
            // Data is array of `Objects` | `Strings`
            const data = await source.json();
            return data;
          } catch (error) {
            return error;
          }
        },
        cache: false,
        // Data 'Object' key to be searched
        keys: ["title", "part_number"],
      },
      resultItem: {
        element: (item, data) => {
          item.innerHTML = `${data.value.part_number} - ${data.value.title}`;
        },
      },
    };

    const autoCompleteJS = new autoComplete(config);

    autoCompleteJS.input.addEventListener("selection", function (event) {
      window.location.href = `/admin/stock_items/${event.detail.selection.value.id}`;
    });
  }
});
